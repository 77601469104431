	/*
  	Flaticon icon font: Flaticon
  	Creation date: 21/04/2020 23:31
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../../fonts/newicons/Flaticon.eot");
  src: url("../../fonts/newicons/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../../fonts/newicons/Flaticon.woff2") format("woff2"),
       url("../../fonts/newicons/Flaticon.woff") format("woff"),
       url("../../fonts/newicons/Flaticon.ttf") format("truetype"),
       url("../../fonts/newicons/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../../fonts/newicons/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 60px;
font-style: normal;
}

.flaticon-cost:before { content: "\f100"; }
.flaticon-employee:before { content: "\f101"; }
.flaticon-admin:before { content: "\f102"; }
.flaticon-law:before { content: "\f103"; }
.flaticon-law-1:before { content: "\f104"; }
.flaticon-law-2:before { content: "\f105"; }
.flaticon-group:before { content: "\f106"; }
.flaticon-test:before { content: "\f107"; }
.flaticon-lawyer:before { content: "\f108"; }
.flaticon-handshake:before { content: "\f109"; }